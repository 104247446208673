












import { PropType } from 'vue'
import { Product } from '@/store/modules/catalog/types'

export default {
  props: {
    product: {
      type: Object as PropType<Product>,
      default: () => ({})
    }
  },

  computed: {
    productImage () {
      if (this.product.image_url) {
        return { backgroundImage: `url('${this.product.image_url}')` }
      }
      return null
    }
  }
}
