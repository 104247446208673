




























import Vue, { PropType } from 'vue'
import { defineDevice } from '@/mixins/mixins'
import { DEVICES_TYPES } from '@/common/constants'

export default Vue.extend({
  name: 'ModalDownload',

  mixins: [defineDevice],

  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  data () {
    return {
      showed: false,
      deviceType: null,
      types: DEVICES_TYPES,
      title: 'Скачивайте perimetr',
      description: 'Заказывайте товары/услуги, исследуйте район. Получайте бонусы и скидки, актуальные предложения!'
    }
  },

  watch: {
    value (val) {
      this.showed = val
    }
  },

  mounted () {
    this.showed = this.value
    this.deviceType = this.defineDevice()
  },

  computed: {
    openStore () {
      // if (this.deviceType === this.types.ios.name) {
      //   return 'https://apps.apple.com/by/app/perimetr/id1542228667'
      // }
      // if (this.deviceType === this.types.android.name) {
      //   return 'https://play.google.com/store/apps/details?id=perimetr.app.bsl.client'
      // }
      // return 'https://play.google.com/store/apps/details?id=perimetr.app.bsl.client'
      return `https://perimetr.page.link/${this.$route.query.hash}`
    }
  }
})
