




























import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import Loading from '@/components/Loading/index.vue'
import ProductsPanel from '@/components/AppPreview/Catalog/ProductsPanel.vue'
import { getAccess } from '@/services/auth/index'
import ModalDownload from '@/components/AppPreview/ModalDownload.vue'
import EmptyMessage from '@/components/EmptyMessage.vue'

export default Vue.extend({
  name: 'Catalog',
  components: {
    Loading,
    ProductsPanel,
    ModalDownload,
    EmptyMessage
  },
  data () {
    return {
      isModalShown: false,
      loading: false,
      deliveryMethod: 'delivery'
    }
  },
  async created () {
    this.loading = true
    await this.getUser()
    await this.getCatalogData()
  },
  computed: {
    ...mapState('profiles', [
      'profile'
    ]),
    ...mapState('catalog', [
      'catalogContent'
    ])
  },
  methods: {
    ...mapActions('profiles', [
      'getBusinessProfile'
    ]),
    ...mapActions('auth', [
      'startAnonymousSession'
    ]),
    ...mapActions('catalog', [
      'getCatalogContent'
    ]),
    async getUser () {
      this.loading = true
      const id = this.$route.params.profileId

      const profileResponse = await this.getBusinessProfile(id)

      this.loading = false
      if (profileResponse.status < 200 || profileResponse.status >= 300) {
        this.$toast.error(profileResponse?.data?.detail?.message ||
          'Извините, произошла ошибка получения профиля. Попробуйте попозже.')
      }
    },
    async getCatalogData () {
      if (!this.profile) return this.$toast.error('Произошла ошибка. Попробуйте попозже.')
      this.loading = true
      const access = getAccess()

      if (!access.expired || Date.now() > access.expired) {
        const sessionResponse = await this.startAnonymousSession()

        if (sessionResponse.status < 200 || sessionResponse.status >= 300) {
          this.$toast.error(sessionResponse?.data?.detail?.message ||
            'Извините, произошла ошибка создания сессии. Попробуйте попозже.')
          this.loading = false
          return
        }
      }

      const catalogId = this.$route.params.catalogId

      const catalogData = await this.getCatalogContent({
        sectionId: catalogId,
        deliveryMethod: this.deliveryMethod
      })

      this.loading = false
      if (this.handleError(catalogData) || !catalogData) {
        return false
      }
    },

    handleError (response) {
      if (response?.status < 200 || response?.status >= 300) {
        this.$toast.error(response?.data?.detail?.message ||
          'Произошла ошибка. Попробуйте попозже.')
        this.$router.push(`/app-preview/${this.route.params.profileId}`)
        return true
      }
      return false
    },

    async handleChangedDelivery (delivery) {
      this.deliveryMethod = delivery
      await this.getCatalogData()
    }
  }
})
