import { DEVICES_TYPES } from '@/common/constants'

const copyToClipboard = {
  methods: {
    async copyToClipboard (text) {
      try {
        await navigator.clipboard.writeText(text)
      } catch {}
    }
  }
}

const defineDevice = {
  methods: {
    defineDevice () {
      const userAgent = navigator.userAgent
      const checker = {
        iphone: userAgent.match(DEVICES_TYPES.ios.regex),
        android: userAgent.match(DEVICES_TYPES.android.regex)
      }
      if (checker.android) {
        return DEVICES_TYPES.android.name
      } else if (checker.iphone) {
        return DEVICES_TYPES.ios.name
      } else {
        return DEVICES_TYPES.desktop.name
      }
    }
  }
}

const formattingDateForRequest = {
  methods: {
    formattingDateForRequest (dateString) {
      const array = dateString.split('-')
      return `${array[2]}-${array[1]}-${array[0]}`
    }
  }
}

export { copyToClipboard, defineDevice, formattingDateForRequest }
