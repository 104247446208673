import { render, staticRenderFns } from "./ModalSharing.vue?vue&type=template&id=b07a1a94&scoped=true&lang=pug&"
import script from "./ModalSharing.vue?vue&type=script&lang=ts&"
export * from "./ModalSharing.vue?vue&type=script&lang=ts&"
import style0 from "./ModalSharing.vue?vue&type=style&index=0&id=b07a1a94&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b07a1a94",
  null
  
)

export default component.exports