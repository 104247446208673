









































import Vue, { PropType } from 'vue'
import { copyToClipboard } from '@/mixins/mixins'

export default Vue.extend({
  name: 'ModalSharing',
  mixins: [copyToClipboard],

  props: {
    qr: {
      type: String as PropType<string>,
      default: ''
    },
    category: {
      type: String as PropType<string>,
      default: ''
    },
    username: {
      type: String as PropType<string>,
      default: ''
    },
    value: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  data () {
    return {
      showed: false,
      description: 'Поделитесь профилем, чтобы другие пользователи смогли подписаться на него'
    }
  },

  watch: {
    value (val) {
      this.showed = val
    }
  },

  mounted () {
    this.showed = this.value
  },

  computed: {
    currentLocation () {
      return window.location.href
    }
  },

  methods: {
    copy () {
      this.copyToClipboard(`https://perimetr.page.link/${this.$route.query.hash}`)
      this.$toast.success('Ссылка успешно скопирована!')
    }
  }
})
