












import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'Patrition',

  props: {
    partition: {
      type: Object as PropType<object>,
      default: () => {}
    }
  },

  computed: {
    image () {
      if (this.partition?.image_url) {
        return this.partition.image_url
      }
      return null
    },
    imageStyle () {
      return { backgroundImage: `url(${this.image})` }
    },
    cardStyle () {
      if (this.partition?.bg_color) return { background: this.partition.bg_color }
      return null
    }
  },
  methods: {
    move () {
      this.$router.push({
        name: 'app-preview-products',
        params: {
          profileId: this.$route.params.profileId,
          catalogId: this.partition.id
        },
        query: { hash: this.$route.query.hash }
      })
    }
  }
})
