


































































import Vue, { PropType } from 'vue'
import { DAYS_OF_WEEK } from '@/common/constants'
import { cloneDeep } from '@/utils/functions'

export default Vue.extend({
  name: 'ProfileCard',
  props: {
    profile: {
      type: Object as PropType<object>,
      default: () => ({})
    }
  },
  computed: {
    background () {
      return `backgroundImage: url('${this.profile.avatar_url}')`
    },
    location () {
      if (this.profile?.profile_data?.location?.geo_point?.longitude &&
      this.profile?.profile_data?.location?.geo_point?.latitude) {
        const longitude = this.profile?.profile_data?.location?.geo_point?.longitude || 37.62
        const latitude = this.profile?.profile_data?.location?.geo_point?.latitude || 55.75
        return {
          url: `http://maps.yandex.ru/?ll=${longitude},${latitude}&z=20`,
          name: this.profile?.profile_data?.location?.address || ''
        }
      }
      return null
    },
    website () {
      if (!this.profile?.profile_data?.website) return null
      if (this.profile?.profile_data?.website.includes('http')) {
        return this.profile?.profile_data?.website
      }
      return 'http://' + this.profile?.profile_data?.website
    },
    category () {
      return this.profile?.profile_data?.category?.name || null
    },
    description () {
      return this.profile?.profile_data?.description || null
    },
    schedule () {
      if (!this.profile?.profile_data?.work_time_schedule.length) return 'Круглосуточно все дни недели'
      const chunckedSchedule = cloneDeep(this.profile?.profile_data?.work_time_schedule)
      chunckedSchedule.sort(function (a, b) {
        return parseInt(a.day_of_week) - parseInt(b.day_of_week)
      })
      let scheduleString = ''
      for (let i = 0; i < chunckedSchedule.length; i++) {
        if (i === chunckedSchedule.length - 1) {
          if (chunckedSchedule[i].works_from.includes('00:00') && chunckedSchedule[i].works_from.includes('00:00')) {
            scheduleString += `${DAYS_OF_WEEK[chunckedSchedule[i].day_of_week]}: Круглосуточно`
          } else {
            scheduleString += `${DAYS_OF_WEEK[chunckedSchedule[i].day_of_week]}: 
          ${chunckedSchedule[i].works_from}-${chunckedSchedule[i].works_until}.`
          }
        } else if (i === chunckedSchedule.length - 1 ||
            chunckedSchedule[i].works_from !== chunckedSchedule[i + 1].works_from ||
            chunckedSchedule[i].works_until !== chunckedSchedule[i + 1].works_until) {
          scheduleString += `${DAYS_OF_WEEK[chunckedSchedule[i].day_of_week]}: 
          ${chunckedSchedule[i].works_from}-${chunckedSchedule[i].works_until}. `
        } else {
          scheduleString += `${DAYS_OF_WEEK[chunckedSchedule[i].day_of_week]}, `
        }
      }
      return scheduleString
    }
  }
})
