

























import Vue, { PropType } from 'vue'
import ModalSharing from './ModalSharing.vue'

export default Vue.extend({
  name: 'Header',

  components: {
    ModalSharing
  },

  props: {
    username: {
      type: String as PropType<string>,
      default: ''
    },
    qr: {
      type: String as PropType<string>,
      default: ''
    },
    category: {
      type: String as PropType<string>,
      default: ''
    }
  },
  data () {
    return {
      isModalShown: false
    }
  }
})
