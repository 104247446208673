











import Vue, { PropType } from 'vue'
import { UpdateProfileBody } from '@/store/modules/pgt/types'
import PartnerCard from './PartnerCard.vue'

export default Vue.extend({
  name: 'PartnersPanel',
  components: {
    PartnerCard
  },
  props: {
    partners: {
      type: Array as PropType<UpdateProfileBody[]>,
      default: () => []
    }
  }
})
