











import { PropType } from 'vue'
import { Product } from '@/store/modules/catalog/types'

export default {
  props: {
    partner: {
      type: Object as PropType<Product>,
      default: () => ({})
    }
  }
}
