
































import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import Partition from './Catalog/Partition.vue'
import ToggleDelivery from './Catalog/Toggle.vue'
import PartnersPanel from './PartnersPanel.vue'

export default Vue.extend({
  name: 'Catalog',

  props: {
    partitions: {
      type: Array as PropType<object>,
      default: () => ([])
    },
    deliveryMethod: {
      type: String as PropType<string>,
      default: ''
    }
  },

  components: {
    Partition,
    ToggleDelivery,
    PartnersPanel
  },

  computed: {
    ...mapState('pgt', [
      'partners'
    ])
  },

  methods: {
    handleChangedDelivery (updatedValue) {
      if (updatedValue === this.deliveryMethod) return
      this.$emit('changed-delivery', updatedValue)
    }
  }
})
