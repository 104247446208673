










import { PropType } from 'vue'
export default {
  props: {
    categories: {
      type: Array as PropType<object>,
      default: () => ([])
    },
    activeCategoryId: {
      type: String as PropType<string>,
      default: ''
    }
  }
}
