
































import { mapState } from 'vuex'
import Vue, { PropType } from 'vue'
import { CatalogContent } from '@/store/modules/catalog/types'
import Categories from './Categories.vue'
import ToggleDelivery from './Toggle.vue'
import Product from './ProductCard.vue'
import PartnersPanel from '../PartnersPanel.vue'

export default Vue.extend({
  name: 'ProductsPanel',

  components: {
    Product,
    Categories,
    PartnersPanel,
    ToggleDelivery
  },

  props: {
    catalog: {
      type: Object as PropType<CatalogContent>,
      default: () => ({})
    },
    deliveryMethod: {
      type: String as PropType<string>,
      default: ''
    }
  },

  data () {
    return {
      selectedCategoryId: null
    }
  },

  mounted () {
    if (!this.categoriesCount) return
    this.selectedCategoryId = this.catalog?.children[0]?.id || null
  },

  computed: {
    ...mapState('pgt', [
      'partners'
    ]),
    selectedCategory () {
      return this.catalog?.children?.find(category => category.id === this.selectedCategoryId) || {
        name: '',
        products: []
      }
    },
    categoriesCount () {
      return this.catalog?.children?.length || 0
    }
  },

  methods: {
    handleChangedDelivery (updatedValue) {
      if (updatedValue === this.deliveryMethod) return
      this.$emit('changed-delivery', updatedValue)
    },
    handleSelectCategory (selectedCategoryId) {
      if (this.selectedCategoryId === selectedCategoryId) return
      this.selectedCategoryId = selectedCategoryId
    }
  }
})
