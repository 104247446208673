







































import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import Header from '@/components/AppPreview/Header.vue'
import ProfileCard from '@/components/AppPreview/ProfileCard.vue'
import ModalDownload from '@/components/AppPreview/ModalDownload.vue'
import Catalog from '@/components/AppPreview/Catalog.vue'
import Loading from '@/components/Loading/index.vue'
import ProductsPanel from '@/components/AppPreview/Catalog/ProductsPanel.vue'
import { getAccess } from '@/services/auth/index'
import EmptyMessage from '@/components/EmptyMessage.vue'

export default Vue.extend({
  name: 'AppPreview',
  components: {
    Header,
    ProfileCard,
    ModalDownload,
    Catalog,
    ProductsPanel,
    Loading,
    EmptyMessage
  },
  data () {
    return {
      isModalShown: false,
      loading: false,
      deliveryMethod: 'delivery'
    }
  },
  async created () {
    await this.getUser()
    await this.getCatalogData()
    if (this.profile?.profile_type === 'pgt') {
      await this.getPgtPartnersData()
    }
  },
  computed: {
    ...mapState('profiles', [
      'profile'
    ]),
    ...mapState('catalog', [
      'catalogCategories',
      'catalogContent'
    ]),
    category () {
      return this.profile?.profile_data?.category?.name || ''
    }
  },
  methods: {
    ...mapActions('profiles', [
      'getBusinessProfile'
    ]),
    ...mapActions('auth', [
      'startAnonymousSession'
    ]),
    ...mapActions('pgt', [
      'getPgtPartners'
    ]),
    ...mapActions('catalog', [
      'getCatalogs',
      'getCatalog',
      'getCatalogCategories',
      'getCatalogContent'
    ]),
    ...mapMutations('catalog', [
      'setCatalogCategories'
    ]),

    async getUser () {
      this.loading = true
      const id = this.$route.params.profileId
      const access = getAccess()
      if (!access.expired || Date.now() > access.expired) {
        const sessionResponse = await this.startAnonymousSession()

        if (sessionResponse.status < 200 || sessionResponse.status >= 300) {
          this.$toast.error(sessionResponse?.data?.detail?.message ||
            'Извините, произошла ошибка создания сессии. Попробуйте попозже.')
          this.loading = false
          return
        }
      }

      const profileResponse = await this.getBusinessProfile(id)

      if (profileResponse.status < 200 || profileResponse.status >= 300) {
        this.$toast.error(profileResponse?.data?.detail?.message ||
          'Извините, произошла ошибка получения профиля. Попробуйте попозже.')
        this.loading = false
        return
      }
      this.loading = false
    },
    async getPgtPartnersData () {
      if (!this.profile) return this.$toast.error('Произошла ошибка. Попробуйте попозже.')
      this.loading = true

      const partners = await this.getPgtPartners({
        id: this.profile.pgt_profile_id,
        limit: 50
      })
      this.loading = false
      if (partners.status < 200 || partners.status >= 300) {
        this.$toast.error(partners?.data?.detail?.message ||
          'Извините, произошла ошибка получения партнеров профиля. Попробуйте попозже.')
      }
    },
    async getCatalogData () {
      if (!this.profile) return this.$toast.error('Произошла ошибка. Попробуйте попозже.')
      this.loading = true

      // get started data for preview
      // before user select some things

      const catalogs = await this.getCatalogs({ businessProfileId: this.profile.profile_data.id })
      if (this.handleError(catalogs) || !catalogs.length) {
        return false
      }

      const catalog = await this.getCatalog({
        profileId: this.profile.profile_data.id,
        catalogId: catalogs[0].id
      })
      if (this.handleError(catalog) || !catalog) {
        return false
      }

      // partition - раздел
      let partitions = await this.getCatalogCategories({
        catalogId: catalog.id
      })
      if (this.handleError(partitions) || !partitions?.length) {
        return false
      }
      this.setCatalogCategories(partitions.slice(1, partitions.length))
      partitions = partitions.slice(1, partitions.length)

      if (partitions.length === 1) {
        const catalogData = await this.getCatalogContent({
          sectionId: partitions[0].id,
          deliveryMethod: this.deliveryMethod
        })

        this.loading = false
        if (this.handleError(catalogData) || !catalogData) {
          return false
        }
      }
      this.loading = false
    },

    handleError (response) {
      if (response?.status < 200 || response?.status >= 300) {
        this.$toast.error(response?.data?.detail?.message ||
          'Произошла ошибка. Попробуйте попозже.')
        console.log('[ ERROR ]', response)
        this.loading = false
        return true
      }
      return false
    },

    async handleChangedDelivery (delivery) {
      this.deliveryMethod = delivery
      await this.getCatalogData()
    }
  }
})
